import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

class Archive extends Component {
  render() {
    let posts = this.props.posts || this.props.data.allWordpressPost.edges
    return (
      <section className='archive container'>
        { posts && posts.map((edge, i) => {
          return (
            <div className='archive__post' key={i}>
              <div className='archive__categories col col-lrg-third'>
                { edge.node.categories.map((cat, i) => <Link key={i} to={`/news/${cat.slug}`}>{ cat.name }</Link>) }
              </div>
              <div className='col col-75 col-lrg-2thirds'>
                <Link to={`/article/${ edge.node.slug }`}>
                  <h4>{ edge.node.title }</h4>
                  <div dangerouslySetInnerHTML={{__html: edge.node.excerpt }} />
                </Link>
              </div>
            </div>
          )
        })
        }
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost {
          edges {
            node {
              title
              slug
              excerpt
              categories {
                name
                slug
              }
            }
          }
        }
      }
      `}
    render={data => <Archive data={data} {...props} />}
  />
)
