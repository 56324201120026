import React, { Component } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import PageCover from '../components/page-cover'
import Archive from '../components/archive'

class Category extends Component {

  render() {
    return (
      <>
        <SEO title="Page" keywords={[`gatsby`, `application`, `react`]} />
        <PageCover>
          <div className='container'>
            <div className='col'></div>
            <div className='col col-half'>
              <h1>{ this.props.data.category.name } News</h1>
            </div>
          </div>
        </PageCover>
        <div className='page-cover__under'>
          <Archive posts={this.props.data.posts && this.props.data.posts.edges} />
        </div>
      </>
    )
  }
}

export default Category

export const categoryQuery = graphql`
  query currentCategoryQuery($id: String!) {
    category: wordpressCategory(id: {eq: $id}) {
      name
    }
    posts: allWordpressPost(filter: {categories: {elemMatch: {id: {eq: $id}}}}) {
      edges {
        node {
          title
          slug
          excerpt
          categories {
            slug
            name
          }
        }
      }
    }
  }
`
