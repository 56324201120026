import React, { Component } from 'react'

class PageCover extends Component {

  state = {
    fixed: null,
    opacity: 1,
  }

  render() {
    let backgroundImage = this.props.background ? `url(${this.props.background})` : `url(https://picsum.photos/1920/1080/?random)`

    return (
      <>
        <section className='page-cover' style={{ opacity: this.state.opacity, backgroundImage }} >
          { this.props.children }
        </section>
      </>
    )
  }
}

export default PageCover
